import React, { useEffect } from 'react';
import { navigate } from '@gatsbyjs/reach-router';

const Callback = () => {
  let redirectPath = '/';
  if (typeof window !== 'undefined') {
    redirectPath = localStorage.getItem('redirectToAfterAuth') || '';
  }

  useEffect(() => {
    navigate(redirectPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>Please wait...</div>;
};

export default Callback;
